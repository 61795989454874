import { createApp } from "/node_modules/.vite/deps/vue.js?v=04fca425"
import "/src/style.css"
import App from "/src/App.vue"
import router from "/src/router/index.js"
import pinia from "/src/stores/index.js"
import { useAuthStore } from "/src/stores/auth.js"

const app = createApp(App)
app.use(pinia)

// Initialize auth state
const authStore = useAuthStore()
authStore.initAuth()

app.use(router)
app.mount('#app')